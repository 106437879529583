import { Circle, Hash } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { checksHooks } from "../../../api";
import { ICheckInputNumberViewModel } from "../../../application/viewModels/CheckInputNumberViewModel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { CheckState } from "../../../infrastructure/api/common/types";
import { Input } from "../../ui/input";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";
interface ICheckPopupInputNumberProps {
  check: ICheckInputNumberViewModel;
  onFinish: () => void;
  title: string;
  signed: boolean;
  sectionTitle: string;
  checklistId: number;
  groupUid: string;
}

export const CheckPopupInputNumber = observer(
  ({
    check,
    onFinish,
    title,
    signed,
    sectionTitle,
    checklistId,
    groupUid,
  }: ICheckPopupInputNumberProps): JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [value, setValue] = useState<number | undefined>(undefined);
    const { t } = useTranslation();

    const { mutate, isLoading: isLoadingValue } = checksHooks.usePut(
      "/checklists/groups/checks/number/value",
    );
    const { mutate: mutateReset, isLoading: isLoadingReset } =
      checksHooks.usePut("/checklists/groups/checks/number/value/reset");
    const { mutate: mutateInApplicable, isLoading: isLoadingInApplicable } =
      checksHooks.usePost("/checklists/groups/checks/number/in-applicable");

    const handleSubmit = async (value: number) => {
      setDialogOpen(false);

      mutate(
        {
          number: value,
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async (data) => {
            console.log({ data });

            const state = (data as any).state;

            await check.store(value, Number(state));

            if (check.finished) {
              onFinish();
            }
          },
        },
      );
    };

    const handleReset = () => {
      mutateReset(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            await check.reset();

            onFinish();
          },
        },
      );
    };

    function onNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
      const value = !Number.isNaN(e.target.valueAsNumber)
        ? e.target.valueAsNumber
        : undefined;

      setValue(value);
    }

    function handleCheckIsInApplicable() {
      mutateInApplicable(
        {
          checklistId: checklistId,
          checkUid: check.uid,
          groupUid,
        },
        {
          onSuccess: async () => {
            // await check.accept();
            check.handleInApplicable();

            onFinish();
          },
        },
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
          isLoading={isLoadingValue || isLoadingInApplicable || isLoadingReset}
        >
          {check.state === CheckState.InApplicable && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
              >
                <Circle className="w-5 h-5 mr-2" />
                {t("inspectionPage.checkPopup.inApplicableCheckButton")}
              </AnswerBadge>
            </AnswerContainer>
          )}

          {check.number !== null && check.state !== CheckState.InApplicable && (
            <AnswerContainer>
              {check.state === CheckState.Bad && check.number !== null ? (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  deleteLabel="Opnieuw invoeren"
                  onReset={handleReset}
                >
                  <Hash className="w-5 h-5 mr-2" />({check.number}){" "}
                  {t("inspectionPage.checkPopup.numberCheck.invalidInput")}
                </AnswerBadge>
              ) : (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <Hash className="w-5 h-5 mr-2" />
                  {check.number}
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}

          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>
                  {t("inspectionPage.checkPopup.numberCheck.numberButton")}
                </DialogTitle>
                <DialogDescription>{check.title}</DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4 w-full">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (value === undefined) return;

                    handleSubmit(value);

                    setValue(undefined);
                    setDialogOpen(false);
                  }}
                >
                  <Input
                    autoFocus
                    className="w-full"
                    placeholder={t(
                      "inspectionPage.checkPopup.numberCheck.numberButton",
                    )}
                    type="number"
                    value={value}
                    onChange={onNumberChange}
                    step={"any"}
                  />
                  <div>
                    <button
                      disabled={value === undefined}
                      className={`mt-3 text-sm border h-10 text-primary rounded-md w-full ${
                        !value ? "opacity-50" : ""
                      }`}
                      type="submit"
                    >
                      {t("inspectionPage.checkPopup.singleLine.saveButton")}
                    </button>
                  </div>
                </form>
              </div>
              <DialogFooter></DialogFooter>
            </DialogContent>

            {((!check.finished && !check.loading) ||
              (check.loading && check.number === null)) &&
              !signed &&
              check.state !== CheckState.Bad &&
              check.state !== CheckState.InApplicable && (
                <div className="w-full">
                  <ActionContainer>
                    <DialogTrigger asChild>
                      <PrimaryActionButton
                        disabled={check.loading}
                        onClick={() => {
                          setDialogOpen(true);
                        }}
                      >
                        {title}
                      </PrimaryActionButton>
                    </DialogTrigger>
                  </ActionContainer>
                  {check.canBeInApplicable && (
                    <div className="px-4 pb-4">
                      <div className="border-t-2 border-dashed pt-4">
                        <SecondaryActionButton
                          onClick={handleCheckIsInApplicable}
                          disabled={check.loading}
                        >
                          {t(
                            "inspectionPage.checkPopup.inApplicableCheckButton",
                          )}
                        </SecondaryActionButton>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </Dialog>
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
